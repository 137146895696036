import axios from "axios";

// Automate baseURL selection
var connectURL = "";
if (process.env.NODE_ENV == "development") {
  connectURL = "http://localhost:3000/";
} else {
  connectURL = "https://gcom.onrender.com";
}
console.log(`connectURL: ${connectURL}`);

const apiClient = axios.create({
  // baseURL: "http://localhost:3000/", // URL for local postgres db
  // baseURL: "https://mjm-g-data-api.herokuapp.com/", // To herokuapp
  // baseURL: "https://mjm-g-data-api.onrender.com", // URL to Render
  baseURL: connectURL,
  withCredentials: false,
  headers: {
    Accept: "application/json", // Any authentication & configuration
    "Content-Type": "application/json", // that the API server may need - see axios docs
  },
});

export default {
  // Clubs
  async getClubs() {
    try {
      return await apiClient.get("/api/clubs");
    } catch (err) {
      console.log(`Error: EnterData.vue getClubs: ${err}`);
    }
  },
  // Players
  async getAllPlayers() {
    try {
      return await apiClient.get("/api/players");
    } catch (err) {
      console.log(`EventService.js getAllPlayers error: ${err}`);
    }
  },
  async postRegistration(registrationData) {
    try {
      // console.log("IN EVENTSERVICE");
      return await apiClient.post("/api/players", registrationData);
    } catch (error) {
      console.log(`EventService.js postRegistration error: ${error}`);
    }
  },
  async login(loginData) {
    try {
      // console.log("IN EVENTSERVICE");
      return await apiClient.patch("/api/players", loginData);
    } catch (error) {
      console.log(`EventService.js login error: ${error}`);
      return error;
    }
  },
  // Percist
  async updatePercist(percist) {
    try {
      // console.log(`IN EVENTSERVICE updateRoundData`)
      return await apiClient.put("/api/percist/update", percist);
    } catch (error) {
      console.log(`Error: EventService.js updatePercist: ${error}`);
    }
  },
  async getPercist(percist) {
    try {
      // console.log(`IN EVENTSERVICE updateRoundData`)
      return await apiClient.put("/api/percist/", percist);
    } catch (error) {
      console.log(`Error: EventService.js getPercist: ${error}`);
    }
  },
  // Courses
  async getAllCourses() {
    try {
      return await apiClient.get("/api/courses");
    } catch (err) {
      console.log(`EventService.js getAllCourses error: ${err}`);
    }
  },
  async getCourseFromRoundId(id) {
    try {
      console.log("IN EVENTSERVICE");
      // console.log(`id: ${id}`);
      return apiClient.get(`api/round-course/${id}`);
    } catch (error) {
      console.log(`EventService.js getCourseFromRoundId error: ${error}`);
    }
  },
};
