<template>
  <Menubar :model="items">
    <template #start>
      <img
        src="../assets/images/m-golf-after-round.png"
        height="40"
        class="p-mr-2"
        alt="logo"
      />
    </template>
    <template #end>
      <Button
        v-if="loggedIn"
        class="p-button-text p-button-secondary"
        label="logout"
        @click="logout"
      ></Button>
      <Button
        v-if="!loggedIn"
        class="p-button-text p-button-secondary"
        label="login"
        @click="login"
      ></Button>
    </template>
  </Menubar>
</template>

<script>
import { ref, computed } from "vue";
import router from "../router";
import store from "../store";

export default {
  name: "menubar.vue",
  setup() {
    function logout() {
      router.push({ name: "Logout" });
    }
    function login() {
      router.push({ name: "Login" });
    }
    const loggedIn = computed(() => {
      // var x = store.getters["player/loggedIn"];
      // console.log(`MainMenu loggedIn: ${x}`);
      return store.getters["player/loggedIn"];
    });
    const items = ref([
      {
        label: "mjm-g-data",
        icon: "pi pi-ellipsis-v",
        items: [
          {
            label: "Login",
            icon: "pi pi-fw pi-sign-in",
            to: { name: "Login" },
          },
          {
            label: "Register",
            icon: "pi pi-fw pi-id-card",
            to: { name: "Register" },
          },
          {
            label: "Settings",
            icon: "pi pi-fw pi-user-edit",
            to: { name: "Settings" },
          },
        ],
      },
      {
        label: "Home",
        icon: "pi pi-fw pi-home",
        to: { name: "Home" },
      },
      {
        label: "Process",
        icon: "pi pi-book",
        items: [
          {
            label: "Create Round",
            icon: "pi pi-fw pi-flag",
          },
          {
            label: "Enter Hole Data",
            icon: "pi pi-fw pi-pencil",
          },
          {
            label: "Display",
            icon: "pi pi-fw pi-chart-line",
            items: [
              {
                label: "Large Table",
                icon: "pi pi-fw pi-table",
              },
              {
                label: "Score Graphs",
                icon: "pi pi-fw pi-chart-line",
              },
              {
                label: "Approach Graphs",
                icon: "pi pi-fw pi-chart-bar",
              },
              {
                label: "Putting Graphs",
                icon: "pi pi-fw pi-chart-bar",
              },
              {
                label: "Scrambling Graphs",
                icon: "pi pi-fw pi-chart-bar",
              },
            ],
          },
        ],
      },
      {
        label: "mjm-g-notes",
        icon: "pi pi-fw pi-external-link",
        url: "https://mjm-g-notes.onrender.com",
      },
      {
        label: "Quit",
        icon: "pi pi-fw pi-power-off",
        to: { name: "Logout" },
      },
    ]);

    return {
      items,
      logout,
      loggedIn,
      login,
    };
  },
};
</script>
