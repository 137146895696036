import { createStore } from "vuex";
import * as player from "@/store/player.js";
import * as course from "@/store/course.js";
import * as club from "@/store/club.js";

export default createStore({
  modules: {
    player,
    course,
    club,
  },
  state: {},
  mutations: {},
  actions: {
    clearAll({ commit }) {
      commit("round/CLEAR_ALL_DATA");
    },
  },
  getters: {},
});
